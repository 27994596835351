.emoji-container {
    scrollbar-width: thin; /* for Firefox */
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.15s linear;
}

.emoji-container:hover {
    scrollbar-color: #cdcdcd transparent;
}

.emoji-grid-item{
    cursor: pointer;
    background-color: white;
    transition:  background-color 0.1s linear;
    z-index:1;
}
.emoji-grid-item:hover{
    background-color:#f9f064;
}

.emoji-group-grid-item{
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    transition:  background-color 0.1s linear;
    z-index: 0;
}
.emoji-group-grid-item:hover{
    /*f5bcb4 #b5e0fe*/
    background-color: #b5e0fe;
}

.emoji-reaction-static{
    cursor: pointer;
    background-color: #f3c1fd;
    transition:  background-color 0.2s linear;
    animation: reaction-spawn 0.1s linear;
}

.emoji-reaction-static:hover{
    background-color: #fddaf9;
}
.emoji-reaction-static:active{
    background-color: #fdb3e0;
}

.happy-icon{
    position: relative;
    top: 2px;
    display: inline-block;
    width:19.06px;
    height:19.94px;
    background-repeat: no-repeat;
    transition: background-color 0.1s linear;
    mask-size: cover;
}

.happy-icon-parent:hover > .happy-icon{
    background-color: #0568FC;
}
.happy-icon-parent:hover > *{
    color: #0568FC;
}

.sad-icon{
    position: relative;
    top: 2px;
    display: inline-block;
    width:19.06px;
    height:19.94px;
    transition: background-color 0.1s linear; 
    mask-size: cover;
}

.sad-icon-parent:hover > .sad-icon{
    background-color: #FF3030;
}

.sad-icon-parent:hover > *{
    color: #FF3030;
}

.comments-button > *{
    margin-left: 0px;
    transition: color 0.1s linear;
}

.comments-button> *:hover{
    color: #494949;
}

.comment-button-icon{
    position: relative;
    top: 4px;
    display: inline-block;
    width: 16px;
    height: 16px;
    mask-size: cover;
    transition: background-color 0.1s linear;
}
.comments-button:hover > * > .comment-button-icon{
    background-color: #494949;
}

.share-button-icon{
    position: relative;
    top: 3px;
    display: inline-block;
    width:16px;
    height:16px;
    transition: background-color 0.1s linear; 
    mask-size: cover;
}


.share-button > *{
    transition: color 0.1s linear;
}

.share-button> *:hover{
    color: #494949;
}

.share-button:hover > * > .share-button-icon{
    background-color: #494949;
}

.img-preview{
    background: rgba(196,196,196,1);

}